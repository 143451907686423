import {
  AimlEUDB,
  CloudParityReportRoute,
  DataFlows,
  EUDBAlerts,
  Models,
  Resources,
  Rti,
  SGIAppView,
  SGIOverview,
  SkuMappingReportRoute,
  Welcome,
  CardinalityView,
  ServiceModels,
} from './Routes'

import { ILandingPage } from './Models'
import { LandingPages } from 'src/enums'

export const landingPages = new Map<LandingPages, ILandingPage>([
  [LandingPages.Welcome, Welcome],
  [LandingPages.RTI, Rti],
  [LandingPages.DataFlows, DataFlows],
  [LandingPages.Resources, Resources],
  [LandingPages.Models, Models],
  [LandingPages.EUDBAlerts, EUDBAlerts],
  [LandingPages.AIML, AimlEUDB],
  [LandingPages.CloudParityReport, CloudParityReportRoute],
  [LandingPages.SkuMappingReport, SkuMappingReportRoute],
  [LandingPages.SGIOverview, SGIOverview],
  [LandingPages.SGIAppView, SGIAppView],
  [LandingPages.CardinalityView, CardinalityView],
  [LandingPages.ServiceModels, ServiceModels],
])
