import { useQuery } from 'react-query'
import { apiService, SwaggerException, DataFlowVM } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getDemoEdges = async (serviceId?: string) => {
  const response = await apiService.threatModels_GetDemoEdges(serviceId)
  return response
}
export function useDemoEdges(serviceId?: string) {
  return useQuery<DataFlowVM[], SwaggerException>([SgQueryKey.demoEdges, serviceId], () => getDemoEdges(serviceId), {
    enabled: !!serviceId,
  })
}
