import { useQuery } from 'react-query'
import { apiService, TMNodeVM, SwaggerException } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getDemoNodes = async (serviceId?: string) => {
  const response = await apiService.threatModels_GetDemoNodes(serviceId)
  return response
}
export function useDemoNodes(serviceId?: string) {
  return useQuery<TMNodeVM[], SwaggerException>([SgQueryKey.demoNodes, serviceId], () => getDemoNodes(serviceId), {
    enabled: !!serviceId,
  })
}
