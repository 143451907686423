import { lazy } from 'react'
import { ILandingPage } from '../Models'

const ServiceModelsPage = lazy(() => import('../../../pages/ServiceModels/ServiceModels'))

export const ServiceModels = {
  navigationTitle: 'Service Models',
  icon: 'Timeline',
  element: <ServiceModelsPage />,
} as ILandingPage
